import React from 'react'
import { Helmet } from 'react-helmet-async';

function HonoraryConsul() {
  return (
    <>
    <Helmet>
        <title>Benin Fahri Konsolosu</title>
        <meta name='description' content='Benin Fahri Konsolosu' />
        <link rel="canonical" href='/honoraryconsul' />
    </Helmet>
    <div className='row justify-content-center'>
        <div className="col-lg-6 col-12 image_col">
            <img className='img-fluid' src="/images/engintuncer.jpg" alt="Engin Tuncer" style={{borderRadius:"20px"}} />
        </div>
        <div className="col-lg-6 col-12 d-flex justify-content-center">
            <div className='content_col'>
                <h1>ENGİN TUNCER</h1>
                <h5>BENİN FAHRİ KONSOLOSU</h5>
            </div>
        </div>
        <div className='mt-4 mt-lg-5'>
            <p>
                <em>
                11 Nisan 1964 yılında Ankara’da doğan Hasan Engin Tuncer, eğitim öğretim hayatını da Ankara’da tamamladı. Küçük yaşlardan beri iş hayatı içerisinde bulundu, eğitimini tamamlanmasının ardından genç yaşta iş hayatına atıldı. 1995 yılında Ankara Lalahan’da son derece modern, yüksek üretim kapasiteli ve teknolojili Uluslararası GMP (Good Manufacturing Practices) standartlarıyla çalışan fabrikayı hayata geçirdi. 
                </em>
            </p>
            <p>
                97 yıldır kesintisiz faaliyetiyle Türkiye’de kültür mirası haline gelmiş Eyüp Sabri Tuncer markasını bir dünya markası yapmak hedefiyle farklı konseptteki markaları bir çatı altında toplayarak Gizli Bahçe, Perfume Jewels ve EST1923 markalarını geliştirdi. Markaya ve ürünlere olan inovatif yaklaşımı ile sektörün öncü isimlerinden biri haline geldi. 2000’li yılların başında online alışveriş sitesini hayata geçirerek markasının online satış kanalında ilkler arasındaki yerini almasını sağladı. 2007 yılından itibaren ürün çeşitliliğini arttırarak ve geliştirerek kişisel bakım ürünü markası haline dönüşen kurum bugün; diş macunu, şampuan, krem, losyon, duş jeli, sıvı sabun olmak üzere bir çok kişisel bakım ve ev bakım ürünlerini üreterek ülkemizin tanınmış markası olarak gelişmeye devam etmektedir. Türkiye’nin ilk Vegan ve Vejetaryen Sertifikalı kozmetik markası olan Eyüp Sabri Tuncer’i, Türkiye’nin dışa açılan markalı kozmetik firması olarak Honk kong’dan Amerika’ya , Avrupa Birliği ülkelerinden Mauritius adalarına kadar uzanan 70’den fazla ülkeye ihracat yapan ülkemizin alanında bayrak taşıyan kurumlarından biri haline gelmesine liderlik etmiştir. 
            </p>
            <p>
                Hasan Engin Tuncer;  Yüzyıllık Markalar Derneği Yönetim Kurulu Başkanı, Prof. Dr. Fuat Sezgin İslam Bilim Tarihi Araştırmaları Vakfı Kurucusu, Mütevelli Heyeti Üyesi ve Yönetim Kurulu Üyesi olarak görevini devam ettirmektedir. Beyoğlu Eğitim ve Kültür Vakfı Yönetim Kurulu Üyesidir. Bunun yanı sıra, ILO (Uluslar Arası Çalışma Örgütü) tarafından kurulan GAN (Küresel İş başında Eğitim Ağı) Türkiye kurucu Yönetim Kurulu Üyesi, UN(Birleşmiş Milletler) Global Compact Sürdürülebilir kalkınma hedefi Türkiye İmzacısı ve Yönetim Kurulu Üyesi, Bahçeli FM Yayıncılık A.Ş’nin Yönetim Kurulu Başkanı, Avrasya Stratejik Araştırmalar Merkezi’nin Mütevelli Yönetim Kurulu üyeliği ile TACCI  (Türk Amerikan Ticaret ve Sanayi Odası Birlikleri Derneği) ve KOCAV (Kültür Ocağı Vakfı) Mütevelli Heyeti Üyesi’dir.
            </p>
            <p>
                2021 yılının Kasım ayında Ankara’da gerçekleşen Türkiye Binicilik Federasyonu Olağan Seçimli Genel Kurulu’nda, 104 delegenin oyuyla başkan seçilen Eyüp Sabri Tuncer Yönetim Kurulu Başkanı Engin Tuncer, her biri kendi alanında başarılı ömrünü atlara ve biniciliğe adamış ekibi ile şeffaf, adil ve yenilikçi bir hizmet uygulamayı kendine ilke ediniyor. Tuncer, katılımcı ve eşitlikçi yönetim anlayışıyla binicilik sporunun kalkınması ve evrensel bir vizyon çerçevesinde ülkemizi uluslararası alanda başarıyı hedeflemektedir.
            </p>
            <p>
                Aynı zamanda Çiftçi kayıt sistemine kayıtlı Profesyonel Çiftçi olarak İzmir Çeşme’de nesli yok olmakta olan sakız koyunu yetiştiriciliğini yaparak ırkın yeniden yaygınlaşması için Ege Üniversitesi ile ortak projeler yürütmektedir. Çiftlikte 300 adet anaç koyun bulunmakta ve yetiştiriciliği yapılmaktadır. Bununla birlikte Manisa Akhisar’da bulunan 1000 ağaçlık zeytin bahçesiyle bölgede modern ve bilinçli zeytin yetiştiriciliği için eğitimler vererek öncülük etmektedir.
            </p>
            <p>
                1994 yılında görevini Sabahattin Tuncer’den devralan ailenin 3. Kuşak temsilcisi Hasan Engin Tuncer,  Eyüp Sabri Tuncer Kozmetik Sanayi A.Ş’de Yönetim Kurulu Başkanı olarak görevine halen devam etmektedir.
            </p>
        </div>

    </div>
    </>
  )
}

export default HonoraryConsul;