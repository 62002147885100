// Kategoriler; 
// 1- categoryId = 1 => Faaliyet fotoğrafları
// 2- categoryId = 2 => Benin tanıtım fotoğrafları
// 3- categoryId = 3 => Medya fotoğrafları

export const photos = [
    {
        "id": 52,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet37.jpg",
        "title": "Beninli İşinsanlarının Türkiye'deki Fuar Programlarında Yardımcı Olduk. 26.11.2024"
    },
    {
        "id": 51,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet36.jpeg",
        "title": "BENİNLİ İŞİNSANLARI GRUBUNA ÜLKEMİZ İLE İŞBİRLİĞİ İMKANLARINI ANLATTIK 17.07.2024"
    },
    {
        "id": 50,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet35.jpg",
        "title": "TURİSTİK AMAÇLARLA İSTANBUL'DA BULUNAN BENİNLİ GRUBUN PROGRAMLARINDA YARDIMCI OLDUK. 16.07.2024"
    },
    {
        "id": 49,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet34.jpg",
        "title": "BENİNLİ İŞİNSANLARI İLE TÜRK GİRİŞİMCİLERİ SOMUT İŞBİRLİKLERİ İÇİN KONSOLOSLUĞUMUZDA BULUŞTURUYORUZ. 11.07.2024"
    },
    {
        "id": 48,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet33.jpeg",
        "title": "BENİN CUMHURİYETİ'NİN KONGO FAHRİ KONSOLOSU MAMADOU YOUNOUSSA VE AILESİNİ MİSAFİR ETTİK 09.06.2024"
    },
    {
        "id": 47,
        "category": 3,
        "imageUrl": "/images/gallery/Medya/medya8.jpg",
        "title": ""
    },
    {
        "id": 46,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet32.jpg",
        "title": "SAĞLIK, TURİZM VE İŞ AMAÇLARI İLE GELEN BENİNLİ GRUBU KONSOLOSLUĞUMUZDA AĞIRLADIK 19.04.2024"
    },
    {
        "id": 45,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet31.jpg",
        "title": "BENİN TİCARET ODASI BAŞKAN YARDIMCISI CASİMİR MİGAN VE HEYETİNİ MİSAFİR ETTİK, 08 MART 2024"
    },
    {
        "id": 44,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet30.jpg",
        "title": "ABOMEY CALAVİ BELEDİYE BAŞKANI VE DANIŞMANINI KONSOLOSLUĞUMUZDA MİSAFİR ETTİK, 16.02.2024"
    },
    {
        "id": 43,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet29.jpg",
        "title": "BENİN İLE  BİLİMSEL ALANDAKİ İŞBİRLİKLERİNİ GENİŞLETMEK İÇİN İSTANBUL ÜNİVERSİTESİ REKTÖRÜ PROF OSMAN BÜLENT ZÜLFİKAR'I ZİYARET ETTİK. 13.02.2024"
    },
    {
        "id": 42,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet28.jpg",
        "title": "GÜNEY KORE İSTANBUL BAŞKONSOLOSU WOO SUNG LEE'YI KONSOLOSLUĞUMUZDA MİSAFİR ETTİK. 23.01.2024"
    },
    {
        "id": 41,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet27.jpg",
        "title": "TÜRKİYE'DEKİ BENİNLİLERE YÖNELİK İKLİM DEĞİŞİKLİĞİ KONUSUNDA ZOOM TOPLANTISI GERÇEKLEŞTİRDİK. 20.01.2024"
    },
    {
        "id": 40,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet26.jpg",
        "title": "HÜKÜMETLERARASI İKLİM DEĞİŞİKLİĞİ PANELİ İÇİN ISTANBUL'DA BULUNAN DİDİER KAKPA'NIN PROGRAMLARINDA YARDIMCI OLDUK. 16.01.2024"
    },
    {
        "id": 39,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet25.jpg",
        "title": "BENİN KALALE BELEDİYE BAŞKANI VE MECLİS ÜYELERİNİ KONSOLOSLUĞUMUZDA MİSAFİR ETTİK, 17.10.2023"
    },
    {
        "id": 38,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet24.jpg",
        "title": "BENİN DIŞ İŞLERİ BAKANLIĞI VE ANIP YETKİLİLERİNİ KONSOLOSLUKTA MISAFIR ETTIK. EKIM 2023"
    },
    {
        "id": 37,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet23.jpg",
        "title": "Kore Ulusal Gününe Benin Cumhuriyetini Temsilen Katıldık. 29.09.2023"
    },
    {
        "id": 36,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet22.jpg",
        "title": "BENİN DIŞ İŞLERİ YETKİLİLERİ BAYAN EVELYN ADAMS ILE TAWES R. ROGER'İ KONSOLOSLUĞUMUZDA MISAFİR ETTİK. 19.09.2023"
    },
    {
        "id": 35,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet21.jpg",
        "title": "BENİNLİ İŞ İNSANLARINI KONSOLOSLUĞUMUZDA MİSAFİR ETTİK 20.07.2023"
    },
    {
        "id": 34,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet20.jpg",
        "title": "TC KOTONOU BÜYÜKELÇİSİ 3.KATİBİ YUNUS EMRE MERMER İLE TÜRKİYE'DE YAŞAYAN BENİNLİLERİN TEMSİLCİLERİNE BİLGİLENDİRME TOPLANTISI ORGANİZE ETTİK. 21.06.2023"
    },
    {
        "id": 33,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet19.jpg",
        "title": "SENEGAL'DEKİ (DAKAR) BENİN FAHRİ KONSOLOSU MOUSSA CASSE'Yİ KONSOLOSLUĞUMUZDA AĞIRLADIK."
    },
    {
        "id": 32,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet18.jpg",
        "title": "BENİN SOSYAL VE EKONOMIK KONSEY. (03.05.2023)"
    },
    {
        "id": 31,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet17.jpg",
        "title": "BENİN VE TOGOLU ÖĞRENCİLERLE BULUŞTUK. (14.04.2023)"
    },
    {
        "id": 30,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet16.jpg",
        "title": "BÜYÜKELÇİ AYŞE SÖZEN USLUER HANIMEFENDİYİ ZİYARET ETTİK. (07.02.2023)"
    },
    {
        "id": 29,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet15.jpeg",
        "title": "DEIK AFRIKA İŞ KOORDİNATÖRÜ VE YARDIMCISINI KONSOLOSLUĞUMUZDA AĞIRLADIK. BENİNLE İLGİLİ PLANANLANAN FAALİYETLERİ KONUŞTUK. (25.01.2022)"
    },
    {
        "id": 28,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet14.jpg",
        "title": "Benin İstanbul Fahri Konsololsluğu'nun Katkıları ile İstanbul Üniversitesi ile Abomey Calavi Üniversitesi Arasında Bilimsel İşbirliği Anlaşması İmzalandı. Belgenin Tamamı için Tıklayınız.",
        "fileUrl": "/files/IU-UAC.pdf"
    },
    {
        "id": 27,
        "category": 3,
        "imageUrl": "/images/gallery/Medya/medya7.jpg",
        "title": "L'Economiste, Benin - 30/01/2023"
    },
    {
        "id": 26,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet13.jpg",
        "title": "BENİN CUMHURİYETİ BÜYÜK MİLLET MECLİSİ BAŞKANI - 19/11/2022"
    },
    {
        "id": 25,
        "category": 3,
        "imageUrl": "/images/gallery/Medya/medya6.jpg",
        "title": "L'Economiste, Benin - 03.11.2022"
    },
    {
        "id": 24,
        "category": 3,
        "imageUrl": "/images/gallery/Medya/medya5.jpg",
        "title": "Tasarruf ve Finans Dergisi'nin Kasım-Aralık 2022, 5.Sayısı indirmek için tıklayınız",
        "fileUrl": "/files/maden22110708320.pdf"
    },
    {
        "id": 23,
        "category": 3,
        "imageUrl": "/images/gallery/Medya/medya4.jpg",
        "title": "Istanbul Benin-Nigeria Networking Meeting Haberi - L'ECONOMIST BENIN 03.11.2022",
    },
    {
        "id": 22,
        "category": 3,
        "imageUrl": "/images/gallery/Medya/medya3.jpg",
        "title": "BENİN İSTANBUL FAHRİ KONSOLOSLUĞUNA ATANMA HABERİ - L'ECONOMIST BENIN 07.07.2022",
    },
    {
        "id": 21,
        "category": 3,
        "imageUrl": "/images/gallery/Medya/medya2.jpg",
        "title": "BENIN GLO-DJIGBE SERBEST BÖLGESİNİ ZİYARETİMİZ - L'ECONOMIST - 24.05.2022",
    },
    {
        "id": 20,
        "category": 3,
        "imageUrl": "/images/gallery/Medya/medya1.jpg",
        "title": "BENİN - TÜRKİYE BİLİMSEL İŞBİRLİKLERİ HABERİ - GÖZLEM GAZETESİ, 08.07.2022",
    },
    {
        "id": 19,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet11.jpg",
        "title": "BENİNLİ İŞ İNSANLARI'NIN MUSIAD İLE BULUŞMASINI ORGANİZE ETTİK.",
    },
    {
        "id": 18,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet10.jpg",
        "title": "FARKLI SEKTÖRLERDEN 21 KİŞİLİK İŞ İNSANI GRUBUNU KONSOLOLUK OFİSİNDE AĞIRLADIK, İŞBİRLİĞİ İMKANLARINI KONUŞTUK. 21.10.2022",
    },
    {
        "id": 17,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet9.jpg",
        "title": "KOTONOU BÜYÜKELÇİMİZ KEMAL ONUR ÖZÇERİ'NİN DAVETİ İLE BENİN'DEKİ TÜRK TOPLULUĞU TEMSİLCİLERİ İLE BULUŞTUK, 18.05.2022",
    },
    {
        "id": 16,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet8.jfif",
        "title": "İSTANBUL İL GÖÇ İDARESİNDEKİ TOPLANTI İÇİN İSTANBUL HAVALİMANI MÜLKİ İDARE AMİRİNİ ZİYARET ETTİK 25.08.2022",
    },
    {
        "id": 15,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet12.jpg",
        "title": "EST BENİNLİ DİŞ SAĞLIĞI GRUBU SÜREKLİ EĞİTİM FAALİYET RAPORU İÇİN TIKLAYINIZ.",
        "fileUrl": "/files/rapportdelaformation_final.pdf"
    },
    {
        "id": 14,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet7.jpg",
        "title": "EST BENİNLİ DİŞ SAĞLIĞI GRUBU",
        "fileUrl": ""
    },
    {
        "id": 13,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet6.jpg",
        "title": "BİR ÜLKE BİR İNSAN",
    },
    {
        "id": 12,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet5.jpg",
        "title": "BENİN DIŞ İŞLERİ BAKANI AURELIEN AGBENONCI'YI, BÜYÜKELÇİMİZİ KEMAL ONUR ÖZÇERİ İLE ZİYARET ETTİK,  17.05.2022",
    },
    {
        "id": 11,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet4.jpg",
        "title": "BENİN DIŞ İŞLERİ BAKANI AURELIEN ABGENONCI VE HEYETİNİ ISTANBUL HAVALİMANI VIP'TE KARŞILADIK 01.06.2022",
    },
    {
        "id": 10,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet3.jpg",
        "title": "BENİN DİJİTALLEŞME BAKANI BAYAN  ZOUMAROU VE AİLESİNİN İSTANBUL'DAKİ TURİSTİK PROGRAMINI HAZIRLADIK. 08.08.2022",
    },
    {
        "id": 9,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet2.jpg",
        "title": "BENIN ABOMEY CALAVI ÜNİVERSİTESİ REKTÖR VEKİLİ PROF. NELLY KELOME'Yİ KONSOLOSLUK OFİSİMİZDE AĞIRLADIK 05.07.2022",
    },
    {
        "id": 8,
        "category": 1,
        "imageUrl": "/images/gallery/Faaliyet/faaliyet1.jpg",
        "title": "ABOMEY CALAVİ ÜNİVERSİTESİ VE İSTANBUL ÜNİVERSİTESİ ARASINDA BİLİMSEL İŞBİRLİĞİ TEMELLERİ ATILDI. 05.07.2022",
    },
    {
        "id": 7,
        "category": 2,
        "imageUrl": "/images/gallery/image7.jpg",
        "title": "",
    },
    {
        "id": 6,
        "category": 2,
        "imageUrl": "/images/gallery/image6.jpg",
        "title": "",
    },
    {
        "id": 5,
        "category": 2,
        "imageUrl": "/images/gallery/image5.jpeg",
        "title": "",
    },
    {
        "id": 4,
        "category": 2,
        "imageUrl": "/images/gallery/image4.jpg",
        "title": "",
    },
    {
        "id": 3,
        "category": 2,
        "imageUrl": "/images/gallery/image3.jpg",
        "title": "",
    },
    {
        "id": 2,
        "category": 2,
        "imageUrl": "/images/gallery/image2.jpg",
        "title": "",
    },
    {
        "id": 1,
        "category": 2,
        "imageUrl": "/images/gallery/image1.jpg",
        "title": "",
    }
]