import React from 'react'
import { Outlet } from "react-router-dom";
import Header from '../Header';
import Navigation from '../Navigation';

function Layout( { translation } ) {
  return (
    <div className='container'>
        <Header translation={ translation } />
        <Navigation translation={ translation } />
        <div className='page'>
            <Outlet />
        </div>
    </div>
  )
}

export default Layout;