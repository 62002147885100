import React from 'react'
import { Helmet } from 'react-helmet-async';

function InvestmentTrade( { translation } ) {
  return (
    <>
    <Helmet>
      <title>Yatırım ve Ticaret</title>
      <meta name='description' content="Benin Cumhuriyetine yatırım ve ticaret hakkında bilgiler" />
      <link rel="canonical" href='/InvestmentTrade' />
    </Helmet>
    <div>
        <h1 style={{textAlign: 'center'}}>{translation('InvestmentTrade.title')}</h1><br />
        <p>Benin, Batı Afrika’nın yükselen bir ticaret ve yatırımlar ülkesidir. 15 ülkeden oluşan toplam 300 milyonu nüfusu barındıran  Batı Afrika Ülkeleri  Ekonomik Topluluğunun (ECOWAS) girişi konumundadır.  Batı Afrika’nın en iyi iş yapılan ikinci ülkesidir. Ülkeye yapılacak yatırımda sermaye büyüklüğüne göre yatırımcıya sağlanan ayrıcalıklar değişmektedir.</p>
        <p>Benin’in ekonomisi büyük ölçüde tarım, ticaret ve hizmet sektörü üzerinde yapılanmıştır. Ülkenin ihracat gelirinin %75’i, tarım sektöründen elde edilmektedir.  Ülkede kurulması planlanan 30 tarım meslek lisesi ile hem tarım sektörü hem de eğitim alanındaki işbirliklerimiz gelişebilir.</p>
        <p>En önemli tarım ürünleri, pamuk, kaju fıstığı ve tropikal meyvelerdir. 2018 yılından bu yana Afrika’nın birinci pamuk üreticisi haline gelmiştir. Üretilen pamuk yüksek kalitedir. Üretimin büyük bir bölümü işlenmeden Uzak Doğu ülkelerine ihraç edilmektedir. Benin’de pamuğu işleyip katma değer üreten yatırımcı arayışı mevcuttur.</p>
        <p>Başka önemli bir gelir kaynağı ise kaju fıstığı sektörüdür. Kaju fıstığı da benzer şekilde işlenmeden Hindistan başta olmak üzere ülkeden ihraç edilmektedir. Kaju fıstığı işleme fabrikası kurulması için büyük potansiyel bulunmaktadır.</p>
        <p>Benin’de yılda 350 ton ananas üretilmekte, bunun büyük çoğunluğu ihraç edilmektedir. Meyve işleme işleme fabrika sayısı sınırlıdır.</p>
        <p>Benin’de ağaç ve orman ürünleri de yabancı yatırımcıların beklendiği bir sektördür. Türk mobilya sektörünün ticaret ve yatırım imkanları açısından ülkede çok önemli bir potansiyel vardır.</p>
        <p>Ticaret, Benin ekonomisinin diğer önemli sektörüdür. Birçok Beninli firma, Benin’e transit olarak ulaşan ve Nijerya, Nijer ve Burkina Faso’ya sevkedilen malların ticaretiyle uğraşmaktadır. Hizmetler sektörü için de özellikle Nijerya’ya gerçekleşen ürünlerin nakliyesi ön plana çıkmaktadır.</p>
        <p>Benin’in en büyük limanı Kotonu’dadır. Cumhurbaşkanı Talon’un talimatı üzerine Hükümet, büyük gelir elde edilen limanın idaresini geçici olarak Avrupalı bir firmaya (Port of Antwerp) vermiştir. Bu adımdan sonra limanda yolsuzluk vakaları azalmış, liman daha etkin idare edilmeye başlamıştır.</p>
        <p>Cumhurbaşkanı Patrice Talon, 2016 yılında iktidara geldikten sonra güçlü bir demokrasi ile refahın artırılması hedeflenmiştir. Benin’in ekonomik ve sosyal gelişimi için ağırlıklı olarak ülkenin alt-yapısının tamamlanmasını hedefleyen projelere yönelmiştir. Bu projeler ağırlıklı olarak, Çin, Fransız ve Türk firmalarına verilmektedir. Pek çok Afrika ülkesinde başarılı projelere imza atan Türk inşaat firması Summa, ülkenin ilk 5 yıldızlı otel inşaatı ve Uluslararası Kongre Merkezi’ni inşaa etmektedir.</p>
        <p>Benin’in Standard & Poors notu B+’tür. Son dönemde birçok alt-yapı projesi İngiltere Eximbank tarafından (UKEF) finanse edilmektedir.</p>
        <p>Turizm alanında projeler bulunmaktadır. Bu çerçevede, Fransız firması "Club Mediterannee" tarihi ve kültürel zenginliklere sahip Ouidah kasabası yakınlarında tatil köyü inşa edilmektedir. Benin Hükümeti, Batı Afrika’nın en geniş safari alanına sahip bölgeye (Pendjari) yabancı turist çekmeye çalışmaktadır.</p>
        <p>Ülkenin beş yıllık (2021 -2026) kalkınma planları içerisinde Benin’deki doğaltaşların ve endüstriyel hammaddelerin değerlendirilmesi de yer almaktadır. Benin Maden kanunu yabancıların arama ve işletme ruhsatı almalarına imkan verir. Gelişimi için büyük bir hammadde ihtiyacı duyan Çin ve Benin ilişkileri düşünüldüğünde madencilik yatırımları uzun vadede önemli olacaktır. Dünyada ön sıralarda yer alan ülkemiz doğaltaş sektörünün Benin’e aktaracağı çok değerli bilgi ve tecrübe birikimi mevcuttur.</p>
        <p>Ülkemizden Benin’e gıda maddeleri ağırlıklı önemli bir ihracat vardır. Türkiye, 2020 yılında Benin’in ilk 10 ithalatçısı arasında girmiştir.</p>
        <br /><br />
        <div style={{textAlign: 'center'}}>
            <img className='img-fluid' src="/images/ihracatPasta.png" alt="Benin ihracat" />
        </div>
    </div>
    </>
  )
}

export default InvestmentTrade