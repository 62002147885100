import React, { useState } from 'react';
import './galeristyle.css';
import ImageModal from './ImageModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { photos } from './photos';
import { Helmet } from 'react-helmet-async';


function ImageGallery({ category }) {

    const [openModal, setOpenModal] = useState({
        isModalOpen: false,
        imageId:'',
        photos: photos
    });

    const handleModel = (e) =>{
        e.preventDefault();
        let eTarget = e.currentTarget;
        let eTargetChildren = eTarget.children;
        let imgId_ = '';

        //Is Child Element Tagname Img Control  

        for (let i = 0; i < eTargetChildren.length; i++) {
            const elementName = eTargetChildren[i].tagName;
            
            if(elementName === 'IMG'){
                imgId_ = eTargetChildren[i].getAttribute('image-id');
                break;
            }
        }

        setOpenModal(prevOpenModal => ({
            ...prevOpenModal,
            isModalOpen: !prevOpenModal.isModalOpen,
            imageId: imgId_
        }))

        document.body.classList.add("modelOpen");
    }

    const imageItem = photos.map((image) => {
        if(image.category === category){
            return(
                <li onClick={handleModel} key={image.id}>
                    <img src={image.imageUrl} alt={image.title} image-id={image.id} />
                    <div className="overlay"><FontAwesomeIcon icon={faSearch} size="2x" /></div>
                </li>
            )
        }
    });

    return (
        <>
            <Helmet>
                <title>{category === 1 ? 'Faaliyet Fotoğrafları': 'Benin Tanıtım Fotografları'}</title>
                <meta name='description' content={category === 1 ? 'benin fahri konsolosluğu faaliyet fotoğraf galerisi':'benin hakkında fotoğraf galerisi'} />
                <link rel="canonical" href={`/gallery/${category === 1 ? 'faaliyet':'tanitim'}`} />
            </Helmet>
            <ul className="image_gallery">
                {imageItem}
            </ul>
            { openModal.isModalOpen && <ImageModal modelInfo={ openModal } setModal = {setOpenModal}/>}
        </>
    )
}

export default ImageGallery