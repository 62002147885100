import React from 'react'
import { Helmet } from 'react-helmet-async';

function Home( { translation } ) {
  return (
    <>
      <Helmet>
        <title>Benin Fahri Konsolosluğu</title>
        <meta name='description' content='Benin Fahri Konsolosluğu resmi web sitesi' />
        <link rel="canonical" href='/' />
      </Helmet>
      <div className="banner text-center">
        <img className='img-fluid' src="/images/photo2.jpeg" alt="Benin photo1" />
      </div>
      <div className='mt-4'>
        {/* <h1 className='text-center text-uppercase mb-4'>{translation('home.title')}</h1> */}
        <div className='home_content'>
          <h1>FAHRİ KONSOLOSUN KARŞILAMA MESAJI</h1>
          <p>Sayın Misafirlerimiz,</p>
          <p>
            Benin Cumhuriyeti İstanbul Fahri Konsolosluğu internet sitesine hoş geldiniz.
          </p>

          <p>
            Togo ve Nijerya’nın arasında yer alan Benin, kuzeyde Nijer nehrinden, güneyde Atlantik Okyanusu’na kadar uzanır. Ülkenin yüzölçümü 112.622 km2 ’dir. Benin’in toplam 121 km sahil şeridi vardır. Ülke topografyasında genel olarak engebeli düzlükler, alçak tepe ve dağlar hâkimdir. Çoğunluğu genç yaklaşık 13 milyona nüfusa sahip Benin’de resmi dil Fransızcadır. Ülke 25 ile 30 derece sıcaklıkları arasında tropikal bir iklime sahip, ne çok sıcak ne de soğuktur.
          </p>
          <p>
            Yüzyıllık bir markanın sahibi olarak ülkemizde ve dünyanın 70 ülkesinde elde ettiğimiz bilgi ve tecrübe birikimini Batı Afrika’nın anahtarı konumundaki Benin’e aktarmaktan sevinçliyiz. Sayın Cumhurbaşkanımız adına imzalanan buyrultu belgemi aldığım 2022 yılı Temmuz ayından itibaren ülkemiz ile Benin’in yakınlaşması ve iyi ilişkilerimizin güçlenmesi adına hemen faaliyetlere başladık.
          </p>
          <p>
            Benin’in en büyük üniversitesi ile İstanbul Üniversitesi ve İstanbul Teknik Üniversitesi arasındaki bilimsel işbirliğinin temellerini attık. Mevcut bilimsel işbirliklerine yeni değerler ekliyoruz. Ülkemiz ile Benin arasındaki 164 milyon doları aşkın ticaret hacmini ayrıca yatırım imkânlarını artırmak için yoğun bir çaba içerisindeyiz.
          </p>
          <p>
            Bütün bu çalışmaları, Benin Cumhuriyeti İstanbul Fahri Konsolosluğunun ilk kurulduğu 2003 yılından bu yana rahmetli Prof. Dr. Orhan Kural ile bu alanda birlikte çalışan Mehmet Atar’ın bilgi ve tecrübe birikimini de ekleyerek devam ettiriyoruz. Bu vesile ile Prof. Kural’ın bu alanda ülkemiz adında yaptığı yararlı çalışmaları yâd ediyoruz.
          </p>
          <p>
            Üç yüz milyon nüfuslu Batı Afrika’nın giriş kapılarından biri olan Benin; tekstil, telekomünikasyon,  gıda, ambalaj, meyve işleme ve  soya yağı üretimi başta olmak üzere pek çok alanda yatırımcılarımıza önemli fırsatlar sunar.
          </p>
          <p>
          Benin Cumhuriyeti İstanbul Fahri Konsolosluğu olarak bu internet sitesi ile özellikle Benin’de yatırım yapmak ve Beninli firmalarla ticaret ilişkileri geliştirmek isteyen Türk işadamlarına, girişimcilerine bilgi verme ve yardımcı olma amacındayız. Sitede Benin hakkında genel bilgileri ve haberlerimizi bulabilirsiniz.
          </p>
          <p>
            En derin saygı ve selamlarımla
          </p>
          <p>
            <b>
              Hasan Engin TUNCER
            </b>
          </p>
          <p>
            <em>
              Benin Cumhuriyeti İstanbul Fahri Konsolosu
            </em>
          </p>
        </div>
      </div>
    </>
  )
}

export default Home