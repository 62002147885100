import React from 'react';
import { NavLink } from 'react-router-dom';

function Navigation({ translation }) {

  return (
    <section className='menu'>
        <nav className="navbar navbar-expand-lg navbar-light bg-white">
            <div className="container-fluid">
                <NavLink to={'/'}>
                    <img className='img-fluid' src="/images/flag.png" style={{width:'80px'}} alt="Benin Flag" />
                 </NavLink>   
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" exact="true" activeclassname="active" to={'/'}>
                                {translation('navbar.home')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/about'}>
                                {translation('navbar.about')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/InvestmentTrade'}>
                                {translation('navbar.InvestmentTrade')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/honoraryconsul'}>
                                {translation('navbar.honoraryconsole')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/gallery'}>
                                {translation('navbar.gallery')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/press'}>
                                {translation('navbar.press')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/benin_community_in_turkiye'}>
                                {translation('navbar.benin_community_in_turkiye')}
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to={'/contact'}>
                                {translation('navbar.contact')}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </section>
  )
}

export default Navigation