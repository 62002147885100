import React from 'react';
import { Routes, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About';
import HonoraryConsul from './components/HonoraryConsul';
import ErrorPage from './components/ErrorPage';
import Gallery from './components/Gallery';
import ImageGallery from './components/Gallery/ImageGallery';
import Contact from './components/Contact';
import InvestmentTrade from './components/InvestmentTrade';
import BeninCommunityInTurkiye from './components/BeninCommunityInTurkiye';

function App() {
  
  const { t } = useTranslation()

  return (
    <>
      <Routes>
        <Route path='/' element={ <Layout translation={ t } />}>
          <Route index element={<Home translation={ t } />} />
          <Route path='/about' element={<About translation={ t } />} />
          <Route path='/honoraryconsul' element={<HonoraryConsul translation={ t } />} />
          <Route path='/InvestmentTrade' element={<InvestmentTrade translation={ t } />} />
          <Route path='/press' element={<ImageGallery category={3} translation={ t } />} />
          <Route path='/gallery' element={<Gallery translation = { t }/>} />
          <Route path='/gallery/faaliyet' element= {<ImageGallery category={1} />} />
          <Route path='/gallery/tanitim' element= {<ImageGallery category={2}/>} />
          <Route path='/contact' element={<Contact translation = { t }/>} />
          <Route path='/benin_community_in_turkiye' element={<BeninCommunityInTurkiye translation = { t }/>} />
          <Route path='*' exact={true} element={<ErrorPage translation = { t }/>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
