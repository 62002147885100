import React from 'react'
import { Helmet } from 'react-helmet-async';

function Contact( { translation } ) {
  return (
    <>
    <Helmet>
        <title>İletişim</title>
        <meta name='description' content='Benin Fahri Konsolosluğu iletişim sayfası' />
        <link rel="canonical" href='/contact' />
    </Helmet>
    <div>
      <p><strong>Hasan Engin TUNCER</strong></p>
      <p><strong>Benin Cumhuriyeti İstanbul Fahri Konsolosu</strong></p>
      <p>Vadistanbul Ofis Blokları Ayazaga Mahallesi.</p>
      <p>Azerbeycan Caddesi 1B Blok No: 3-B K.20. Ickapı No: 45,</p>
      <p>34485, Sarıyer, Istanbul</p>
      <p>E-posta: <a href = "mailto: info@beninhc.com">info@beninhc.com</a></p>
    </div>
    </>
  )
}

export default Contact