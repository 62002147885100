import React from 'react'
import { Helmet } from 'react-helmet-async';

function BeninCommunityInTurkiye( { translation } ) {
  return (
    <>
    <Helmet>
        <title>Türkiye'deki Benin Topluluğu</title>
        <meta name='description' content="Benin Fahri Konsolosluğu Türkiye'deki Benin Topluluğu" />
        <link rel="canonical" href='/benin_community_in_turkiye' />
    </Helmet>
    <div>
        <h1>Türkiye'deki Benin Topluluğu</h1>
      <a href="/files/NOTEDE_SERVICE-18112024.pdf" download>Note de Service - 18.11.2024 (Procuration, Laissez - Passer, Autorisation Parentale)</a>
    </div>
    </>
  )
}

export default BeninCommunityInTurkiye