import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'bootstrap/dist/js/bootstrap.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icons.min.css';


import './index.css';
import App from './App';
import LoadingMarkup from './components/LoadingMarkup';

import './i18n';


const root = createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<LoadingMarkup/>}>
    {/* <React.StrictMode> */}
      <HelmetProvider>
        <BrowserRouter basename='/'>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    {/* </React.StrictMode> */}
  </Suspense>
);
