import React from 'react'
import { Helmet } from 'react-helmet-async';

function About( { translation } ) {
  return (
    <>
      <Helmet>
        <title>Benin Hakkında</title>
        <meta name='description' content='Benin Cumhuriyeti hakkında bilgilendirme sayfası' />
        <link rel="canonical" href='/about' />
      </Helmet>
      <div className="row justify-content-evenly">
        <div className="col-lg-3 col-12 order-lg-1">
          <div className="about_cardInfo">
            <div className="about_cardInfo_column">
              <h4>{translation('benin')}</h4>
            </div>
            <div className="about_cardInfo_row">
              <div className="flag text-center">
                <img className='img-fluid mb-2' src="/images/flag.png" width="125" height="83" alt="Benin Flag" />
                <span className="d-block text-capitalize">{translation('flag')}</span>
              </div>
              <div className="coatOfArms text-center">
                <img className='img-fluid mb-2' src="/images/Coat_of_arms.svg.png" width="85" height="75" alt="Benin Flag" />
                <span className="d-block text-capitalize">{translation('arms')}</span>
              </div>
            </div>
            <div className="about_cardInfo_column">
              <span className="text-center fw-bold w-100">{translation('motto')}</span>
              <p className="text-center">Fraternité, Justice, Travail</p>
            </div>
            <div className="about_cardInfo_column">
              <img className="img-fluid" src="/images/LocationBenin.png" alt="" />
            </div>
          </div>
        </div>
        <div className="accordion col-lg-8 col-12 order-lg-0" id="aboutPage">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                {translation('about_accordion_item1_header')}
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#aboutPage">
              <div className="accordion-body">
                <p>Benin Cumhurbaşkanı, Patrice Talon,  ülkesini yatırım merkezi ve üretim üssü haline getirmeye çalışmaktadır. Kurulan Ajansla (APIEX) yabancı yatırımcıları ülkeye çekme gayreti devam etmektedir. İleride inşa edilmesi düşünülen yeni havalimanın bulunduğu bölgeye (Glo-Djigbe) yeni bir organize sanayi bölge kurulması devam etmektedir..</p>
                <p>Yatırımcılar bu bölgeye yönlendirilmektedir. Bu bölgenin hemen bitişiğinde 2019 yılında tamamlanan Maria Gleta 2 elektrik santrali bulunmaktadır. 130 MW’lık kapasiteye sahip bu santral serbest bölgedeki fabrikalara elektrik sağlayacaktır.</p>
                <p>Hintli sermayeye firma ARISE tarafından Hükümetle birlikte işletilecek olan serbest bölgeye şimdiden birçok yabancı firma yerleşmeyi kararlaştırmıştır. Benin Hükümeti, Türk firmalarının ülkelerine özellikle tekstil alanında yatırım yapmasını istemektedir.</p>
                <p>Kurulan serbest bölgenin amacı, öncelikle pamuk, ananas, soya, kaju fıstığı ve karite gibi yerel ham maddelerin yerinde işlenerek iplik, kumaş, yağ, meyve suyu gibi katma değere sahip ürünlere dönüştürülmesi ve bu ürünlerin ihraç edilmesidir.</p>
                <p>Ham madde olarak kullanılacak pamuk, ananas, kaju fıstığı, soya ve karitenin çiftçiden satın alınarak, organize sanayi bölgesine ulaştırılmasını serbest bölge yönetimi tarafından sağlanacaktır.</p>
                <p>Yararlı Linkler : <a href="https://gdiz-benin.com/">gdiz-benin</a> - <a href="https://apiex.bj/">Apiex</a></p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Benin Giriş Vizesi Hakkında
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#aboutPage">
              <div className="accordion-body">
                <p>Dijitalleşme konusunda önemli atılımlar yapan Benin Cumhuriyeti’ne giriş için vize başvuruları da internet üzerinden yapılmaktadır.Başvuru linki aşağıda yazılmıştır</p>
                <p><a href="https://evisa.bj/"><b>Başvuru Linki</b></a></p>
                <p>Vize ücreti süreye bağlı olarak 50 ile 100 Euro arasında değişmekte,  internetten başvuru sırasında kredi kartı ile yapılmaktadır.</p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Benin Ulaşım ve Turizm
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#aboutPage">
              <div className="accordion-body">
                <h3>Ulaşım</h3>
                <p>Türk Hava Yolları Benin’e ilk uçuşunu 24 Haziran 2014’te gerçekleştirdi. Benin’e haftanın her günü  günü  Türk Hava Yollarının konforu  ile yaşanan yaklaşık 7  saatlik bir yolculuğun ardından ulaşmak mümkündür. Yine Türk havayolları ile haftada her gün Benin’den İstanbul’a dönmek mümkündür.</p>
                <h3>Turizm</h3>
                <p>Bir zamanlar, Dahomey olarak bilinen, Benin Cumhuriyeti. önemli turistik mekanlarla dolu çok yönlü bir ülkedir. Kültür turizmi, deniz kenarı turizmi, ekoturizm, tarım turizmi, inanç turizmi ve el sanatları dahil olmak üzere her türlü turizmi uygulama imkanı vardır.</p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                Türkiye'deki Benin Topluluğu
              </button>
            </h2>
            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#aboutPage">
              <div className="accordion-body">
                <p>Türkiye’de çoğunluğu öğrenci olan 250 civarında Benin vatandaşı yaşar. Türkiye’deki farklı illerde bulunan Benin öğrencilerinin çoğunluğu, Fransızca, İngilizce ve Türkçe’nin yanısıra Afrika’da konuşulan yerel dilleri bilmektedir. Konsolosluğumuzda mezuniyetine az bir zaman kalan öğrencilerin CV’leri mevcuttur.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default About