import React from 'react';
import { NavLink } from 'react-router-dom';
import './galeristyle.css';
import { Helmet } from 'react-helmet-async';

function Gallery( { translation } ) {

  return (
    <>
        <Helmet>
            <title>Galeri</title>
            <meta name='description' content='Benin Fahri Konsolosluğu galeri sayfası' />
            <link rel="canonical" href='/gallery' />
        </Helmet>
        <div className='gallery'>
            <NavLink exact="true" to={'/gallery/faaliyet'}>
                <div className='card'>
                        <h3 className="card-title">Faaliyet Fotoğrafları</h3>
                        <img src="./images/gallery/Faaliyet/faaliyet1.jpg" alt="ABOMEY CALAVİ ÜNİVERSİTESİ VE İSTANBUL ÜNİVERSİTESİ ARASINDA BİLİMSEL İŞBİRLİĞİ TEMELLERİ ATILDI. 05.07.2022" />
                </div>
            </NavLink>
            <NavLink exact="true" to={'/gallery/tanitim'}>
                <div className='card'>
                    <h3 className="card-title">Benin Tanıtım Fotoğrafları</h3>
                    <img src="./images/gallery/image3.jpg" alt="" />
                </div>
            </NavLink>
        </div>
    </>
  )
}

export default Gallery