import React, { useRef, useEffect } from 'react';
import { photos } from './photos';

function ImageModal({ modelInfo, setModal}) {

  const ref = useRef(null);

  useEffect(() => {
    
    const modelElement = ref.current;

    modelElement.addEventListener('click', handleModel);

    return () => { modelElement.removeEventListener('click', handleModel)}
  }, []);

  const handleModel = (e) =>{

    if(!e.target.classList.contains('modalImage')){
      setModal(prevOpenModal => ({
          ...prevOpenModal,
          isModalOpen: !prevOpenModal.isModalOpen,
          imageId: ''
      }));
      document.body.classList.remove('modelOpen');
    }

    
  }

  let currentPhoto = photos.filter(photo => photo.id == modelInfo.imageId)[0];
  console.log(currentPhoto.fileUrl ==! '');

  return (
    <div ref={ref} className="imageModal">
        <div className='imageModel_header'>
          <button onClick={handleModel}>X</button>
        </div>
        <div className='imageModel_content'>
            <img className='modalImage' src={currentPhoto.imageUrl} alt={currentPhoto.title} />
            <p style={{color:'white'}}>
              {
                currentPhoto.fileUrl?.length !== 0 ? <a style={{color:'inherit'}} href={currentPhoto.fileUrl} download>{currentPhoto.title}</a> : currentPhoto.title
              }
            </p>
        </div>
    </div>
  )
}

export default ImageModal