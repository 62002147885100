import React from 'react';
import i18next from 'i18next';
import cookies from 'js-cookie';

function Header({ translation }) {
    const languages = [
        {
          code: 'tr',
          name: 'Türkçe',
          country_code: 'tr'
        },
        {
          code: 'en',
          name: 'English',
          country_code: 'gb'
        },
        {
          code: 'fr',
          name: 'Français',
          country_code: 'fr'
        },
        {
          code: 'es',
          name: 'Español',
          country_code: 'es'
        },
    ];

    const currentCountryCode = cookies.get('i18next') === 'en' ? 'gb' : cookies.get('i18next');

    return (
        <section className='top_header'>
            <div className="container">
                <header>
                    <div className="text-center">
                        <h2>{translation('header.title')}</h2>
                    </div>
                    <div className="language">
                        <div className="dropdown">
                            <button className="btn btn-link" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span 
                                    className={`flag-icon flag-icon-${currentCountryCode} mx-2`}
                                ></span>
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <span className='dropdown-item-text'>{translation('language')}</span>
                                </li>
                                {languages.map(({code, name, country_code}) => (
                                    <li key={country_code}>
                                        <button 
                                            className="dropdown-item" 
                                            onClick={() => { i18next.changeLanguage(code) }}
                                            disabled={code === i18next.resolvedLanguage}
                                        >
                                            <span 
                                                className={`flag-icon flag-icon-${country_code} mx-2`}
                                            ></span>
                                            {name}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </header>
            </div>
        </section>
    )
}

export default Header