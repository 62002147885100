import React from 'react'

function LoadingMarkup() {
  return (
    <div className='loading'>
        <div className='loading_spinner'>
        </div>
    </div>
  )
}

export default LoadingMarkup